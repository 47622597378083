exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-how-to-create-simple-erc-4337-smart-wallet-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/opt/buildhome/repo/src/posts/how-to-create-simple-erc4337-smart-wallet/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-how-to-create-simple-erc-4337-smart-wallet-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-introducing-kriptonio-web-3-platform-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/opt/buildhome/repo/src/posts/introducing-kriptonio-web3-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-introducing-kriptonio-web-3-platform-index-mdx" */)
}

